module.exports = function (theme) {
  return {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        borderRadius: theme.spacing(6),
        border: `1px solid ${theme.palette.grey.main}`,
      },
      editor: {
        padding: theme.spacing(4),
        minHeight: '12em',
      },
      editorContainer: {
        paddingBottom: theme.spacing(4),
      },
      placeHolder: {
        padding: theme.spacing(4),
        color: theme.palette.grey.main,
        margin: 0,
        marginTop: theme.spacing(1),
      },
      hidePlaceholder: {
        display: 'block',
      },
      toolbar: {
        position: 'absolute',
        bottom: '0px',
      },
    }
  }
}
