import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@mui/material'

import { Warning } from '@mui/icons-material'
import { styled } from '@mui/system'

const IconWarning = styled(Warning)(({ theme }) => ({
  fontSize: '44px',
  color: theme.palette.secondary.main,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

function WarningNote({ sxProp, text }) {
  return (
    <Box
      sx={sxProp}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      m={8}
    >
      <IconWarning />
      <Typography variant="h5">{text}</Typography>
      <IconWarning />
    </Box>
  )
}

WarningNote.propTypes = {
  sxProp: PropTypes.object,
  text: PropTypes.string,
}

export default WarningNote
