import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const SnackbarContext = createContext()

export function SnackbarProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('error')
  const [props, setProps] = useState({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 6000,
  })

  function setSnackbarProps(newprops) {
    setProps({
      ...props,
      newprops,
    })
  }

  function toggleSnackbar(message, alertVariant = 'error') {
    setMessage(message)
    setAlertVariant(alertVariant)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <SnackbarContext.Provider
      value={{ open, setSnackbarProps, toggleSnackbar }}
    >
      {children}
      <Snackbar open={open} onClose={handleClose} {...props}>
        <Alert elevation={6} variant="filled" severity={alertVariant}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.any,
}

export default SnackbarContext
