import React from 'react'
import PropTypes from 'prop-types'

import { Container, Box } from '@mui/material'
import LoginCard from '@components/logincard'

export const frontmatter = {
  title: 'Login',
}

function LoginPage({ data }) {
  return (
    <Container maxWidth="sm">
      <Box my={{ xs: 4, md: 20, lg: 25 }}>
        <LoginCard />
      </Box>
    </Container>
  )
}

LoginPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default LoginPage
