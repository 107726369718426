import React from 'react'
import { Box } from '@mui/material'

function Loading() {
  return (
    <Box
      zIndex="modal"
      position="fixed"
      width={1}
      height={1}
      background="black"
    >
      Laden...
    </Box>
  )
}

export default Loading
