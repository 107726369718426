import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  Button,
} from '@mui/material'
import { useIntl, FormattedMessage } from 'react-intl'
import useAuth from '@hooks/useAuth'
import { navigate } from 'gatsby'

const inputStyle = { WebkitBoxShadow: '0 0 0 0 red inset' }

const validationSchema = Yup.object({
  name: Yup.string().required('Name wird benötigt.'),
  password: Yup.string().required('Passwort wird benötigt.'),
})

function LoginCard() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const { LoggingIn, initialising } = useAuth()
  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      name: '',
      password: '',
    },
    validationSchema,
    onSubmit(values) {
      setLoading(true)
      LoggingIn(values.name, values.password).then((success) => {
        if (success) navigate('/', { replace: true })
      })
    },
  })

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: 'login.title' })} />
      <Divider />
      <CardContent>
        <form>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="name-input"
                data-testid="mail-input"
                fullWidth
                variant="outlined"
                inputProps={{ style: inputStyle }}
                type="name"
                name="name"
                label="Name"
                helperText={errors.name ? errors.name : null}
                error={!!errors.name}
                value={values.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="pw-input"
                data-testid="pw-input"
                fullWidth
                variant="outlined"
                inputProps={{ style: inputStyle }}
                name="password"
                label="Passwort"
                type="password"
                helperText={errors.password ? errors.password : null}
                error={!!errors.password}
                value={values.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                onClick={handleSubmit}
                disabled={initialising || loading}
              >
                <FormattedMessage id="button.login" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

LoginCard.propTypes = {}

export default LoginCard
