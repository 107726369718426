const CssBaseline = require('./overrides/baseline')
const Icons = require('./overrides/icons')
const RichtextEditor = require('./overrides/richtexteditor')

module.exports = function (theme) {
  return {
    MuiCssBaseline: CssBaseline(theme),
    MuiSvgIcon: Icons(theme),
    MuiToolbar: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: theme.container.lg,
          marginLeft: '0px',
          marginRight: 'auto',
        },
      }
    },
    MUIRichTextEditor: RichtextEditor(theme),
    MuiCardHeader: {
      styleOverrides: {
        title: {
          marginBottom: '0px',
        },
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(4, 3),
          '&:last-child': {
            paddingBottom: theme.spacing(8),
          },
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '20px',
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '40%',
        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: theme.spacing(3),
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          marginLeft: 'auto',
          marginRight: 0,
        },
      }
    },
  }
}
