module.exports = {
  xs: '11px',
  sm: '14px',
  base: '16px', // copy desk, tag not stage
  baseXS: '17px', // copy mobile
  lg: '18px', // h5 mobile/ desk, tag stage
  '2lg': '20px', // h4 mobile, intro mobile
  xl: '24px', // h3 mobile, h4 desk
  '2xl': '26px', // zitat desk
  '3xl': '28px', // h2 mobile, h3 desk, intro desk
  '4xl': '32px', // h1 mobile
  '5xl': '34px', // h2 desk
  '6xl': '44px', // h1 desk
}
