const { createTheme } = require('@mui/material/styles')

const Breakpoints = require('./breakpoints')
const Colors = require('./colors')
const Container = require('./container')
const Typography = require('./typography')
const Overrides = require('./overrides')

module.exports = function () {
  const baseTheme = createTheme({
    breakpoints: Breakpoints,
    palette: Colors,
    spacing: 4,
    container: Container,
  })
  const typography = Typography(baseTheme)
  const components = Overrides(baseTheme)

  const theme = createTheme({
    ...baseTheme,
    typography,
    components,
  })

  theme.themeName = 'Deutschlandtakt'

  return theme
}
