// const BP = require('./breakpoints')
const FontSize = require('./definitions/fontSize')

module.exports = function (theme) {
  return {
    fontFamily: 'BundesSans, Helvetica Arial, sans-serif',
    body1: {
      lineHeight: 1.75,
    },
    body2: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['baseXS'],
      lineHeight: 1.625,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['base'],
      },
    },
    h1: {
      fontSize: FontSize['4xl'],
      lineHeight: 1.2,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['6xl'],
      },
    },
    h2: {
      fontSize: FontSize['3xl'],
      lineHeight: 1.2,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['5xl'],
      },
    },
    h3: {
      fontSize: FontSize['xl'],
      lineHeight: 1.2,
      fontWeight: 500,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['3xl'],
      },
    },
    h4: {
      fontSize: FontSize['2lg'],
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['xl'],
      },
    },
    h5: {
      fontSize: FontSize['lg'],
      lineHeight: 1.2,
      fontWeight: 500,
    }
  }
}
