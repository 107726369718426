import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'
import { Card, CardContent, CardHeader } from '@mui/material'

function Answer({ sxProp }) {
  return (
    <Card
      data-testid="answer"
      sx={{
        width: '100%',
        height: '100%',
        m: 4,
        sxProp,
      }}
    >
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={30}
            width="30%"
            style={{ marginBottom: 6 }}
            type="text"
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            height={30}
            width="50%"
            style={{ marginBottom: 6 }}
            type="text"
          />
        }
      />
      <CardContent>
        <Skeleton
          animation="wave"
          height={20}
          style={{ marginBottom: 6 }}
          type="text"
        />
        <Skeleton
          animation="wave"
          height={20}
          style={{ marginBottom: 6 }}
          type="text"
        />
        <Skeleton
          animation="wave"
          height={20}
          style={{ marginBottom: 6 }}
          type="text"
        />
      </CardContent>
    </Card>
  )
}

Answer.propTypes = {
  sxProp: PropTypes.object,
}

export default Answer
