const FontSize = require('../definitions/fontSize')

module.exports = function (theme) {
  return {
    styleOverrides: {
      root: {
        fontSize: FontSize['lg'],
        verticalAlign: 'middle',
      },
      fontSizeSmall: {
        fontSize: FontSize['baseXS'],
        [theme.breakpoints.up('lg')]: {
          fontSize: FontSize['base'],
        },
      },
      fontSizeLarge: {
        fontSize: FontSize['3xl'],
      },
    }
  }
}
