import React from 'react'
import Skeleton from '@mui/material/Skeleton'

function TableSkeleton() {
  return (
    <Skeleton variant="rectangular" sx={{ width: '100%', height: '500px' }} />
  )
}

export default TableSkeleton
