import React from 'react'
import PropTypes from 'prop-types'
import { Box, Fade } from '@mui/material'

import Answer from './types/answer'
import Question from './types/question'
import TableSkeleton from './types/table'

function LoadingSkeleton({
  sxProp,
  children,
  type,
  number,
  loading,
  childSxProp,
}) {
  function renderType(idx) {
    switch (type) {
      case 'question':
        return <Question sxProp={childSxProp} key={`question${idx}`} />
      case 'answer':
        return <Answer sxProp={childSxProp} key={`answer${idx}`} />
      case 'table':
        return <TableSkeleton sxProp={childSxProp} key={`table${idx}`} />
      default:
        return <Question sxProp={childSxProp} key={`question${idx}`} />
    }
  }

  function renderItem() {
    if (loading) {
      let row = []
      for (let i = 0; i < (number || 1); i++) {
        row.push(renderType(i))
      }
      return row
    }
    return (
      <Fade in={!loading} timeout={800}>
        <div>{children}</div>
      </Fade>
    )
  }

  return (
    <Box data-testid="skeleton" sx={sxProp}>
      {renderItem()}
    </Box>
  )
}

LoadingSkeleton.propTypes = {
  sxProp: PropTypes.object,
  childSxProp: PropTypes.object,
  children: PropTypes.any,
  number: PropTypes.number,
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default LoadingSkeleton
