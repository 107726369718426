import React from 'react'
import PropTypes from 'prop-types'

function RootIndex({ pageContext }) {
  return <div>404</div>
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default RootIndex
