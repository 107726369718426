import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { IconButton, Menu, MenuItem, Divider } from '@mui/material'
import { MoreVertRounded } from '@mui/icons-material'

function ContextMenu({ mailto, onCopy, onDelete }) {
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function CopyText() {
    if (onCopy) onCopy()
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertRounded />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          component="a"
          href={`mailto:${intl.formatMessage({
            id: 'menu.sentmail.mailadress',
          })}?${mailto}`}
        >
          Als E-Mail versenden
        </MenuItem>
        <Divider />
        {!!onCopy && <MenuItem onClick={CopyText}>Text kopieren</MenuItem>}
        {!!onCopy && <Divider />}
        {!!onDelete && <MenuItem onClick={onDelete}>Anfrage löschen</MenuItem>}
      </Menu>
    </>
  )
}

ContextMenu.propTypes = {
  className: PropTypes.string,
  mailto: PropTypes.string,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
}

export default ContextMenu
