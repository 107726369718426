const FontFamily = require('../definitions/fontFamily')

module.exports = function (theme) {
  return {
    styleOverrides: {
      '@font-face': FontFamily,
      // TODO: add more basic stylings, eg for links?
      a: {
        color: 'inherit',
        textDecoration: 'none',
        transitionProperty: 'all',
        transitionDuration: theme.transitions.duration.standard,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        '&:hover': {
          color: theme.palette.red.default,
          textDecoration: 'underline',
        },
      },
    },
  }
}
